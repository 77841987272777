<template>

  <router-view v-slot="{ Component }">
    <transition :name="transitionName" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  <CookieBanner />

</template>
<script>
import Cookies from 'js-cookie'
import CookieBanner from './plugins/CookieBanner.vue';

export default {
  name: 'App',
  components: { CookieBanner },
  data() {
    return {
      transitionName: 'slideNormal',
      transitioning: false,
      previousRoute: null,
      displayCookie: true,
      cookiesAccepted: false
    }
  },
  methods: {
    checkCookieStatus() {
      const cookiesRejected = Cookies.get('cookiesRejected');
      if (cookiesRejected) {
        this.accepted = false;
        this.displayCookieBanner();
      }
    },
    displayCookieBanner() {
      const banner = document.querySelector('.height_banner')
      if (banner) {
        banner.style.display = "block";
        banner.style.opacity = "1";
        var alertText = document.getElementById('alert-text');
        alertText.style.display = 'block';
      }
    }
  },
  created() {
    this.previousRoute = this.$route.path;

    this.checkCookieStatus();
    setInterval(this.checkCookieStatus, 30000);
    // Vérifiez si le cookie `cookiesRejected` existe initialement
    if (Cookies.get('cookiesRejected')) {
      this.accepted = false;
      setTimeout(() => {
        this.displayCookieBanner();
      }, 60000);
    }
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    isEnterPage() {
      return this.$route.path === '/enter' || this.$route.path === '/';
    }
  },
  watch: {
    '$route'() {
      this.transitioning = true
    }
  },
  beforeRouteLeave(to, from, next) {
    this.transitioning = false
    next()
  },
  mounted() {
  }
}
</script>

<style>
@import url("https://use.typekit.net/ptd8amo.css");
@import url("https://use.typekit.net/evu1xsd.css");
@import url("https://use.typekit.net/pip0aal.css");

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVc.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVc.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4gaVc.ttf) format('truetype');
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500');
/* CMS_2024 */

.fonts {
  font-family: "courier-prime", monospace;
  font-family: "alegreya-sans-sc", sans-serif;
  font-family: "source-serif-4", serif;
  font-family: "alumni-sans", sans-serif;
  font-family: "chivo-mono", sans-serif;
  font-family: "cormorant", sans-serif;
  font-family: "familjen-grotesk", sans-serif;
  font-family: "vollkorn-sc", sans-serif;
  font-family: "grenze-gotisch", sans-serif;
  font-family: "piazzolla-variable", sans-serif;
  font-family: 'Open Sans';
  font-family: 'Roboto', sans-serif;
  font-family: "amplitude", sans-serif;
  font-family: "amplitude-compressed", sans-serif;
  font-family: "amplitude-condensed", sans-serif;
  font-family: "amplitude-wide", sans-serif;
  font-family: "amplitude-extra-compressed", sans-serif;
  font-family: "pragmatica", sans-serif;
  font-family: "pragmatica-condensed", sans-serif;
  font-family: "pragmatica-extended", sans-serif;
  font-family: "fields", sans-serif;
  font-family: "tiffin-latin-variable", sans-serif;
  font-family: "logical-variable", sans-serif;
  font-family: "obliqua", sans-serif;
  font-family: "apolline", serif;
  font-family: "abril-display", serif;
  font-family: "big-caslon-fb", serif;
  font-family: "gimlet-text", serif;
  font-family: "meno-display", serif;
  font-family: "roc-grotesk", sans-serif;
  font-family: "roc-grotesk-condensed", sans-serif;
  font-family: "indivisible", sans-serif;
  font-family: "articulat-cf", sans-serif;
  font-family: "dupincel-variable", sans-serif;
  font-family: "larken", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

@font-face {
  font-family: 'ManoloMono';
  src: url('/public/fonts/ManoloMono.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ortland';
  src: url('/public/fonts/Ortland.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



@import url('./styles/reset.css');
/* @import url('./styles/base.css');
@import url('./styles/home.css');
@import url('./styles/menu.css');
@import url('./styles/gallery.css');
@import url('./styles/transitions.css');
@import url('./styles/responsive.css'); */

#app {
  position: absolute;
  top: 0px;
  left: 0px;
  height: auto;
  /* transition: zoom 0.8s ease; */
}

.blurred--content {
  display: none !important;
}
</style>
