import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios';

import IndexPage from './components/INDEX/IndexPage.vue'

const routes = [
    {
        name: 'IndexPage',
        path: '/',
        route: '/',
        component: IndexPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
];

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    const routeName = to.path;
    const routeMeta = to.path === '/' ? '/index' : to.path;
    let jsonData;

    try {
        const response = await axios.get(`api/meta${routeMeta}.json`);
        jsonData = response.data;

        // Mettre à jour les métadonnées de la page
        document.title = to.meta.title = jsonData.title;

        const updateMetaTag = (name, content) => {
            let tag = document.querySelector(`meta[name="${name}"]`);
            if (tag) {
                tag.content = content;
            } else {
                tag = document.createElement('meta');
                tag.name = name;
                tag.content = content;
                document.head.appendChild(tag);
            }
        };

        if (jsonData.description) {
            updateMetaTag('description', jsonData.description);
        } else {
            const existingDescription = document.head.querySelector('meta[name="description"]');
            if (existingDescription) {
                existingDescription.remove();
            }
        }

        if (jsonData.keywords) {
            updateMetaTag('keywords', jsonData.keywords);
        } else {
            const existingKeywords = document.head.querySelector('meta[name="keywords"]');
            if (existingKeywords) {
                existingKeywords.remove();
            }
        }
    } catch (error) {
        console.error(`Impossible de charger les données JSON pour la page ${routeName}: `, error);
        // Vous pouvez choisir de gérer l'erreur en naviguant vers une page d'erreur ou en affichant un message
    }

    next();
});

export default router;