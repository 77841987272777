<template>
    <main>
        <div class="flex__cont">
            <span>hello@<a class="hover-effect change-color" href="mailto:hello@closetonone.com" @mouseenter="colorBg"
                    @mouseleave="blackBg" @click="trackMail">CLOSETONONE</a>.com</span>
            <span class="desc"><label>développement web full-stack</label></span>
        </div>


        <!-- <section class="content">
            <h2 class="content__title">Développement web</h2>
            <li class="list__item">
                <span class="list__item-col hover-effect">JONASFORCHINI.COM</span>
            </li>
        </section> -->

        <!-- <BackgroundThree /> -->

    </main>
</template>

<script>
import { TextAnimator } from './text-animator.js'
// import BackgroundThree from './BgThree.vue';

export default {
    // components: { BackgroundThree },
    methods: {
        colorBg() {
            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
            if (!isTouchDevice) {
                document.body.style.backgroundColor = "#074cec"
            }
        },
        blackBg() {
            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
            if (!isTouchDevice) {
                document.body.style.backgroundColor = "black"
                document.querySelector('.flex__cont span').style.color = "white"
            }
        },
        gtag() {
            window.dataLayer.push(arguments);
        },
        trackMail() {
            this.gtag('event', 'Click on Mail (Index - hello@closetonone.com)', {
                'event_category': 'Interaction',
                'event_label': 'Click on Mail (Index - hello@closetonone.com)'
            });

            this.gtag('event', 'Click on Mail (All - hello@closetonone.com)', {
                'event_category': 'Interaction',
                'event_label': 'Click on Mail (All - hello@closetonone.com)'
            });
        }
    },
    mounted() {
        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        if (isTouchDevice) {
            document.body.style.backgroundColor = "black"
        }

        document.querySelectorAll('.list__item').forEach(item => {
            const cols = Array.from(item.querySelectorAll('.hover-effect'));
            const animators = cols.map(col => new TextAnimator(col));

            item.addEventListener('mouseenter', () => {
                animators.forEach(animator => animator.animate());
            });
        });

        // Same for all links
        document.querySelectorAll('.hover-effect').forEach(item => {
            const animator = new TextAnimator(item);
            item.addEventListener('mouseenter', () => {
                animator.animate();
            });
        });
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Nanum+Gothic+Coding&display=swap');
@import url(./styles__index.css);
</style>