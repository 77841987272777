import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import LenisPlugin from './lenis.js'
import { TroisJSVuePlugin } from 'troisjs';

import axios from 'axios'
import gsap from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as THREE from 'three'

const VueApp = createApp(App);

VueApp.config.globalProperties.$axios = axios;
VueApp.config.globalProperties.$gsap = gsap;
VueApp.config.globalProperties.$scrollTrigger = ScrollTrigger;
VueApp.config.globalProperties.$three = THREE;

VueApp.use(router);
VueApp.use(LenisPlugin);
VueApp.use(TroisJSVuePlugin);


VueApp.mount('#app');
